import { useGetProjectsQuery } from "../../../services/api"
import { GetProjectsArgs } from "../../../services/types/project"
import {
  selectCustomers,
  selectProjectStatuses,
  selectProjectDateStart,
  selectProjectDateEnd,
  selectProjectCount,
} from "../insightsSlice"
import { useAppSelector } from "../../../app/hooks"

import { BarChart } from "@mui/x-charts/BarChart"
import { CircularProgress, Grid } from "@mui/material"

const valueFormatter = (value: number | null) => {
  return value === null ? "" : `£${value.toLocaleString("en-gb")}`
}

export default function Charts() {
  const customers = useAppSelector(selectCustomers)
  const projectStatuses = useAppSelector(selectProjectStatuses)
  const projectDateStart = useAppSelector(selectProjectDateStart)
  const projectDateEnd = useAppSelector(selectProjectDateEnd)
  const projectCount = useAppSelector(selectProjectCount)

  const getProjectArgs: GetProjectsArgs = {
    page: undefined,
    limit: projectCount,
    search: undefined,
    customers,
    projectStatuses,
    projectDateStart,
    projectDateEnd,
  }

  const { data, error, isLoading } = useGetProjectsQuery(getProjectArgs)

  const records = data?.records || []

  return error ? (
    <>Error fetching projects</>
  ) : isLoading ? (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "100%" }}
    >
      <Grid item xs={3}>
        <CircularProgress />
      </Grid>
    </Grid>
  ) : (
    <BarChart
      dataset={records}
      margin={{ bottom: 100 }}
      xAxis={[
        {
          scaleType: "band",
          dataKey: "ref",
          tickLabelStyle: {
            angle: -90,
            textAnchor: "end",
          },
        },
      ]}
      series={[
        {
          dataKey: "calculated_value_charged_excluding_vat",
          label: "Revenue",
          valueFormatter,
          color: "#0099ff",
        },
        {
          dataKey: "calculated_value_paid_excluding_vat",
          label: "Expenditure",
          valueFormatter,
          color: "#cc0000",
        },
      ]}
      height={500}
    />
  )
}
